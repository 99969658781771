<template>
  <div class="container-fluid">
    <div class="card border-0 shadow-none">
      <div class="card-body">

        <div class="row mb-4 ms-1">
          <select
            v-model="status"
            class="form-control"
            style="max-width: 250px;"
          >
            <option value="new">Show new</option>
            <option value="read">Show read</option>
          </select>
        </div>

        <div v-if="!busy" class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th scope="col"></th>
                <th scope="col">Who</th>
                <th scope="col">What</th>
                <th scope="col">When</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="m in messages.data" :key="m.id">
                <td @click="viewSms(m)" scope="row">
                  <i
                    class="far"
                    :class="m.type == 'in' ? 'fa-arrow-down' : 'fa-arrow-up'"
                  ></i>
                </td>
                <td @click="viewSms(m)">{{ m.client ? m.client.name : "-" }}</td>
                <td @click="viewSms(m)" style="max-width: 640px">
                  {{ m.message }}
                </td>
                <td @click="viewSms(m)">{{ m.created_at | formatDate }}</td>
                <td class="my-auto">
                  <button
                    v-if="m.status == 'new'"
                    @click="updateStatus(m)"
                    class="btn btn-sm btn-outline-success cursor-pointer"
                    title="Mark as read"
                  >
                    <i class="far fa-check"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <loading v-else class="my-4"></loading>

        <div class="row mt-4">
          <div class="col-md-4 my-auto">
            <button
              class="btn btn-sm btn-outline-success"
              @click="messages.current_page = 1"
              :disabled="messages.current_page == 1"
            >
              First Page
            </button>

            <button
              class="btn btn-sm btn-outline-success ms-3"
              @click="messages.current_page--"
              :disabled="!messages.prev_page_url"
            >
              Previous
            </button>
          </div>
          <div class="col-md-4 my-auto text-center">
            <p class="mb-0">
              Page {{ messages.current_page }} of
              {{ messages.last_page }}
            </p>
          </div>
          <div class="col-md-4 my-auto text-end">
            <button
              class="btn btn-sm btn-outline-success cursor-pointer me-3"
              @click="messages.current_page++"
              :disabled="messages.current_page == messages.last_page"
            >
              Next
            </button>

            <button
              class="btn btn-sm btn-outline-success cursor-pointer"
              @click="messages.current_page = messages.last_page"
              :disabled="messages.current_page == messages.last_page"
            >
              Last Page
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "../../components/loaders/Loading";

export default {
  data() {
    return {
      busy: true,
      messages: {
        current_page: 1,
      },
      status: 'new'
    };
  },
  watch: {
    "messages.current_page": {
      handler: function (val, old) {
        if (val != old) {
          this.$router
            .replace({
              path: this.$route.currentPath,
              query: {
                page: this.messages.current_page,
              },
            })
            .catch(() => {});
          this.fetchSms();
        }
      },
    },
    "status": {
      handler: function (val, old) {
        if (this.messages.current_page === 1) {
          this.fetchSms();
        } else {
          this.messages.current_page = 1;
        }
      }
    }
  },
  methods: {
    updateStatus(m) {
      const index = this.messages.data.indexOf(m);
      if (index > -1) {
        this.messages.data.splice(index, 1);
      }

      m.status = "read";
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/sms/" + m.id, m)
        .then(({ data }) => {
          this.$EventBus.$emit("alert", data);
        });
    },
    viewSms(m) {
      this.$router.push("/clients/" + m.client.id + "#sms");
    },
    fetchSms() {
      this.busy = true;

      this.$axios
        .get(
          process.env.VUE_APP_API_URL + this.$route.fullPath,
          { params: { status: this.status } }
        )
        .then(({ data }) => {
          this.messages = data;
          this.busy = false;
        });
    },
  },
  created() {
    this.fetchSms();
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("L");
    },
  },
  components: {
    Loading
  }
};
</script>

<style>
</style>